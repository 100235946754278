<template>
  <div class="ui segment credits-view">
    <h3>{{ t('user.edit.api.title') }}</h3>
    <p style="display: flex;">
      {{ current_user_api_key  }} 
      <CTooltip :isDynamic="true" :text="t('layout.shared.actions.copy')" :textDynamic="t('layout.shared.actions.copied')">
        <i class="clipboard outline link icon" @click="copyApiKey()"></i>
      </CTooltip>
    </p>
    <p>{{ t('user.edit.api.description') }}</p>
    <button class="ui authot button" @click="openApiDocumentation()">{{ t('user.edit.api.documentation_button') }}</button>
  </div>
</template>

<script>
import CTooltip from '../../../components/authotElements/CTooltip.vue';

export default {
  props: ["current_user_api_key"],
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  data() {
    return {
      apiDocumentUrl: "https://documenter.getpostman.com/view/6136336/2sAYQUptiP"
    }
  },
  methods: {
    copyApiKey() {
      let copyContent = this.current_user_api_key;
      $('<input>').val(copyContent).appendTo('body').select();
      document.execCommand('copy');
    },
    openApiDocumentation() {
      window.open(this.apiDocumentUrl,'_blank').focus();
    }
  },
  components: {
    CTooltip
  }
}
</script>